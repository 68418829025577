import { useState, useMemo, useCallback, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ArrowRightAlt, Pending } from "@mui/icons-material";
import dayjs from "dayjs";

import { COLORS } from "../theme";

import PageSpinner from "./PageSpinner";
import FeaturedEventCard from "./FeaturedEventCard";
import EventCard from "./EventCard";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";

export default function EventSearch({
  events: initialEvents,
  title,
  bgColor,
  locations = [],
  ages = [],
}) {
  const searchParams = useSearchParams();

  const initialAge =
    searchParams.get("ageFrom") && searchParams.get("ageTo")
      ? `${searchParams.get("ageFrom")} - ${searchParams.get("ageTo")}`
      : null;

  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [date, setDate] = useState(
    searchParams.get("date") ? dayjs(searchParams.get("date")) : null
  );
  const [location, setLocation] = useState(searchParams.get("location"));
  const [eventTypeSearch, setEventTypeSearch] = useState(
    searchParams.get("type")
  );
  const [ageRange, setAgeRange] = useState(initialAge);
  const [eventType, setEvent] = useState();

  const router = useRouter();

  const handleSearch = (e) => {
    e.preventDefault();
    const [ageFrom, ageTo] = ageRange?.indexOf(" - ") > -1 
      //e.g. "18 - 30"
      ? ageRange.split(" - ") 
      //e.g  "18" or "18+"
      : [ageRange.replace('+', '').trim(), null]
    // or if no age range is selected
    ?? [undefined, undefined];

    const queries = {
      ...router.query,
      location,
      type: eventTypeSearch,
      ageFrom,
      ageTo,
      date:
        date && date.year()
          ? `${date.year()}-${date.month() + 1}-${date.date()}`
          : "",
    };

    if (!location) delete queries.location;
    if (!eventTypeSearch) delete queries.type;
    if (!ageFrom) delete queries.ageFrom;
    if (!ageTo) delete queries.ageTo;
    if (!date) delete queries.date;
    if (date && (!date.year() || !date.month() || !date.date()))
      delete queries.date;
    setIsSearching(true)
    console.log(queries)
    const params = new URLSearchParams(queries)
    router.push(`?${params.toString()}`, undefined, { shallow: true })
    fetch('/api/eventSearch?'+params.toString()).then(res => res.json())
    .then(data => {
      // console.log(data)
      setSearchResults(data.results)
    })
    .finally(() => {setIsSearching(false)})
  };

  const events = searchResults || initialEvents;

  return (
    <>
      <Box bgcolor={bgColor || COLORS.lightGrey} minHeight="inherit">
        <Container>
          <Box>
            {events.length > 0 ? (
              <Typography
                variant={title ? "h4" : "h1"}
                sx={(theme) => ({
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontSize: "32px",
                  paddingTop: theme.spacing(4),
                  "& .event-span": {
                    color: theme.palette.primary.main,
                  },
                  [theme.breakpoints.down("xs")]: {
                    fontSize: 30,
                  },
                })}
              >
                {title || (
                  <>
                    <strong>Find your</strong> Perfect Dating Events
                  </>
                )}
              </Typography>
            ) : (
              <Box textAlign="center" pt={10}>
                <Typography variant="h6">
                  We currently have no events available...
                </Typography>
              </Box>
            )}
            <Box my={4}>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md>
                    <Autocomplete
                      fullWidth
                      options={locations}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, newValue) => {
                        setLocation(newValue);
                      }}
                      value={location}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    />
                  </Grid>

                  <Grid item xs={6} sm>
                    <TextField
                      fullWidth
                      label="Event Type"
                      select
                      variant="outlined"
                      defaultValue=""
                      value={eventTypeSearch ?? ""}
                      onChange={(e) => {
                        setEventTypeSearch(e.target.value);
                      }}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    >
                      <MenuItem value={""}>All</MenuItem>
                      <MenuItem value={"Speed"}>Speed Dating</MenuItem>
                      <MenuItem value={"Single"}>Singles Party</MenuItem>
                      <MenuItem value={"Christian"}>
                        Christian Speed Dating
                      </MenuItem>
                      <MenuItem value={"Gay"}>Gay Singles Party</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm>
                    <TextField
                      fullWidth
                      onChange={(e) => setAgeRange(e.target.value)}
                      label="Age"
                      select
                      variant="outlined"
                      value={ageRange ?? ""}
                      defaultValue=""
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    >
                      <MenuItem key={`ageRange_None`} value={""}>
                        <em>None</em>
                      </MenuItem>
                      {ages.map((range, i) => {
                        return (
                          <MenuItem key={`ageRange_${i}`} value={range}>
                            {range}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm>
                    <DatePicker
                      autoOk
                      disableToolbar
                      label="Date"
                      format="DD/MM/YYYY"
                      value={date}
                      onChange={(date) => setDate(date)}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                        width: "100%",
                      })}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item>
                    <Box height="100%" display="flex" alignItems="center">
                      <Button
                        size={title ? "large" : "medium"}
                        fullWidth
                        variant="contained"
                        color="primary"
                        endIcon={isSearching ? <Pending /> : <ArrowRightAlt />} 
                        type="submit"
                        disabled={isSearching}
                      >
                        Search
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <Grid container spacing={2}>
              {events.length > 0 &&
                events.map((event, index) =>
                  event.featured ? (
                    <Grid item container key={`event_${index}`}>
                      <FeaturedEventCard {...event} />
                    </Grid>
                  ) : (
                    <Grid item key={`event_${index}`} xs={12} sm={6} md={4}>
                      <EventCard {...event} />
                    </Grid>
                  )
                )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
